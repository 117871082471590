import { Flex } from 'antd'

import { SENSOR_NAME_MAP } from '@/constants/dashboard'
import { useColor } from '@/hooks'

import { StyledCard } from '../atoms'

type SensorCardProps = {
  title: string
  data?: {
    temperature: number
    humidity: number
    pressure: number
  }
}

export const SensorCard = ({ title, data }: SensorCardProps) => {
  const colors = useColor()
  const metrics = [
    { value: data?.temperature ?? 0, unit: '℃' },
    { value: data?.humidity ?? 0, unit: '%' },
    { value: data?.pressure ?? 0, unit: 'Pa' },
  ]

  return (
    <StyledCard styles={{ body: { height: '100%', padding: 12 } }}>
      <p
        className="mb-1 text-lg font-bold 2xl:text-xl"
        style={{ color: colors.title }}
      >
        {title ? SENSOR_NAME_MAP[title] : '-'}
      </p>
      <Flex gap={24} justify="center">
        {metrics.map((metric, index) => (
          <div key={index} className="flex items-center gap-1 text-center">
            <span className="text-lg 2xl:text-xl">
              {metric.value ? Math.trunc(metric.value * 10) / 10 : ''}
            </span>
            {metric.unit}
          </div>
        ))}
      </Flex>
    </StyledCard>
  )
}
