import React from 'react'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Carousel, Empty } from 'antd'
import { CarouselRef } from 'antd/es/carousel'
import dayjs from 'dayjs'

import UserImg from '@/assets/img/user.png'
import { carouselResponse } from '@/constants/dashboard'

import './PersonCarousel.css'

import { StyledCard } from '../atoms'

interface PersonCarouselProps {
  data?: (UserData & { image: string })[]
}

export const PersonCarousel = ({ data = [] }: PersonCarouselProps) => {
  const carouselRef = React.useRef<CarouselRef>(null)

  return data.length ? (
    <div className="relative mx-auto px-14">
      <LeftOutlined
        className="absolute left-5 top-1/2 z-10 -translate-y-1/2 text-xl"
        onClick={() => carouselRef.current?.prev()}
      />

      <Carousel
        ref={carouselRef}
        slidesToShow={4}
        dots={false}
        infinite={false}
        swipeToSlide={true}
        draggable={true}
        slidesToScroll={4}
        style={{
          WebkitUserSelect: 'none',
          MozUserSelect: 'none',
          msUserSelect: 'none',
          userSelect: 'none',
          cursor: 'grab',
        }}
        responsive={carouselResponse}
      >
        {data
          .sort((a, b) => b.timestamp - a.timestamp)
          .map((user) => (
            <div key={user.uuid} className="px-2">
              <StyledCard
                className="text-center"
                styles={{
                  body: { height: 'calc(100vh * 0.32)' },
                }}
              >
                <div className="flex h-full flex-col items-center justify-around gap-2">
                  <div className="flex h-32 w-32 items-center justify-center overflow-hidden rounded-full 2xl:h-36 2xl:w-36">
                    <img
                      src={
                        user.image
                          ? `data:image/png;base64, ${user.image}`
                          : UserImg
                      }
                      alt="user image"
                      className="h-full w-full object-cover"
                    />
                  </div>
                  <div>
                    <h3 className="font-medium sm:text-lg 2xl:text-3xl">
                      {user.name}
                    </h3>
                    <span className="text-gray-500 sm:text-sm 2xl:text-2xl">
                      {dayjs(user.timestamp).format('HH:mm')}
                    </span>
                  </div>
                </div>
              </StyledCard>
            </div>
          ))}
      </Carousel>

      <RightOutlined
        className="absolute right-5 top-1/2 z-10 -translate-y-1/2 text-xl"
        onClick={() => carouselRef.current?.next()}
      />
    </div>
  ) : (
    <div className="p-4">
      <Empty
        className="text-base 2xl:text-2xl"
        image={Empty.PRESENTED_IMAGE_SIMPLE}
      />
    </div>
  )
}
