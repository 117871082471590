import { PropsWithChildren } from 'react'
import { Layout } from 'antd'

import { useColor } from '@/hooks'

import { DigitalClock, GIPSLogo } from '../atoms'
import { Tabbar } from '../molecules'

const { Header, Content } = Layout

export const ContentLayout = ({ children }: PropsWithChildren) => {
  const colors = useColor()

  return (
    <Layout
      className="h-full p-6"
      style={{ backgroundColor: colors.background, color: colors.text }}
    >
      <Header
        className="flex h-auto flex-row justify-between px-4"
        style={{ backgroundColor: colors.background }}
      >
        <div className="flex w-full flex-row items-center justify-between gap-6">
          <GIPSLogo />
          <Tabbar />
          <DigitalClock />
        </div>
      </Header>
      <Content>{children}</Content>
    </Layout>
  )
}
