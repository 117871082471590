import { createTrackedSelector } from 'react-tracked'
import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

interface SettingState {
  theme: 'light' | 'dark'
  locale: string
  warningSpeaker: boolean
  toggleTheme: () => void
  setLocale: (locale: string) => void
  setWarningSpeaker: (warningSpeaker: boolean) => void
}

const SettingStore = create<SettingState>()(
  persist(
    devtools(
      (set) => ({
        theme: 'dark',
        locale: 'zh',
        warningSpeaker: true,
        toggleTheme: () => {
          set((state) => ({
            theme: state.theme === 'light' ? 'dark' : 'light',
          }))
        },
        setLocale: (locale: string) => set({ locale }),
        setWarningSpeaker: (warningSpeaker: boolean) => set({ warningSpeaker }),
      }),
      { name: 'setting store', serialize: { options: true } },
    ),
    {
      name: 'setting_storage',
      getStorage: () => window.localStorage,
    },
  ),
)

export const useSettingStore = createTrackedSelector(SettingStore)
