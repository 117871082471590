import { QueryFunction, useQuery } from '@tanstack/react-query'
import axios from 'axios'

import { API } from '@/utils/API'

const apiRoute = API.aiot

export const useSensors = () => {
  const getSensorsData: QueryFunction<GetSensorsResponse> = async () => {
    const { data } = await axios.get<GetSensorsResponse>(apiRoute.sensor)
    return data
  }

  const sensorsDataQuery = useQuery({
    queryKey: ['sensors'],
    queryFn: getSensorsData,
    refetchInterval: 60 * 1000,
  })

  return {
    query: sensorsDataQuery,
  }
}
