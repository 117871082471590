import { EllipsisOutlined } from '@ant-design/icons'
import { Avatar, List, Tag } from 'antd'

import userAvatar from '@/assets/img/user.png'
import { useColor } from '@/hooks'

interface PersonListProps {
  data: ItemQueryContent[]
}

export const PersonList = ({ data }: PersonListProps) => {
  const colors = useColor()
  return (
    <List
      className="person-list"
      style={{ overflow: 'scroll', maxHeight: 'calc(100vh * 0.35)' }}
      itemLayout="horizontal"
      dataSource={data}
      renderItem={(item) => (
        <List.Item
          actions={[
            <Tag
              key={item.lastBoundTagId}
              className="text-sm 2xl:text-lg"
              color={colors.primary}
            >
              {item.lastBoundTagId}
            </Tag>,
            <EllipsisOutlined key="more" />,
          ]}
        >
          <List.Item.Meta
            avatar={<Avatar src={userAvatar} className="2xl:h-10 2xl:w-10" />}
            title={
              <span className="text-base 2xl:text-2xl 2xl:leading-10">
                {item.displayName}
              </span>
            }
          />
        </List.Item>
      )}
    />
  )
}
