import { QueryFunction, useQuery } from '@tanstack/react-query'
import axios from 'axios'
import dayjs from 'dayjs'

import { API } from '@/utils/API'

interface DateRange {
  startDate: dayjs.Dayjs | undefined
  endDate: dayjs.Dayjs | undefined
}

const apiRoute = API.event

export const useAbnormalRecords = ({ dateRange }: { dateRange: DateRange }) => {
  const getAbnormalRecords: QueryFunction<GetEventsResponse> = async () => {
    const params: GetEventsParams = {
      startDateTime: dayjs(dateRange.startDate).startOf('day').format(),
      endDateTime: dayjs(dateRange.endDate).endOf('day').format(),
      limit: Number.MAX_SAFE_INTEGER,
    }
    const { data } = await axios.get<GetEventsResponse>(apiRoute.list, {
      params,
    })

    return data
  }

  const abnormalRecordsQuery = useQuery({
    queryKey: ['abnormalRecords', dateRange],
    queryFn: getAbnormalRecords,
    enabled: !!dateRange.startDate && !!dateRange.endDate,
    select: (data) => {
      return data.sort((a, b) => {
        return dayjs(b.createdAt).diff(dayjs(a.createdAt))
      })
    },
  })

  return {
    abnormalRecordsQuery,
  }
}
