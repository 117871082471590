import { lazy, Suspense } from 'react'
import { Spin } from 'antd'

const Map = lazy(() => import('../organisms/Map'))

export const GIPSensePage = () => {
  return (
    <div className="h-full w-full p-6">
      <Suspense fallback={<Spin size="large" />}>
        <Map />
      </Suspense>
    </div>
  )
}
