import { useEffect, useState } from 'react'
import { MutationFunction, useQuery } from '@tanstack/react-query'
import axios from 'axios'
import dayjs from 'dayjs'

import { API } from '@/utils/API'

import { useToken } from './useToken'

export const useUsers = () => {
  const { token } = useToken()
  const [users, setUsers] = useState<any[]>([])

  const getUserImage = async (uuid: string) => {
    const res = await axios.post<GetUserImageResponse>(
      API.airafacelite.fetchPhoto,
      { uuid },
      { headers: { token: token.data } },
    )
    return res.data
  }

  const getPersonList: MutationFunction<GetPersonListResponse> = async () => {
    const res = await axios.post<GetPersonListResponse>(
      API.airafacelite.person,
      {
        start_time: dayjs().startOf('day').valueOf(),
        end_time: dayjs().endOf('day').valueOf(),
        slice_shift: 0,
        slice_length: 250,
        with_image: true,
        uuid_list: [],
      },
      { headers: { token: token.data } },
    )
    return res.data
  }

  const personListQuery = useQuery({
    queryKey: ['personList'],
    queryFn: getPersonList,
    enabled: !!token.data,
    refetchInterval: 10000, // 10 sec
    select: (data) => data.result.data,
    onError: async (error: any) => {
      if (error.response?.status === 401) {
        await token.refetch()
      }
    },
  })

  const getVisitorList: MutationFunction<GerVisitorListResponse> = async () => {
    const res = await axios.post<GerVisitorListResponse>(
      API.airafacelite.visitor,
      {
        start_time: dayjs().startOf('day').valueOf(),
        end_time: dayjs().endOf('day').valueOf(),
        slice_shift: 0,
        slice_length: 250,
        with_image: true,
        uuid_list: [],
      },
      { headers: { token: token.data } },
    )
    return res.data
  }

  const visitorListQuery = useQuery({
    queryKey: ['visitorList'],
    queryFn: getVisitorList,
    enabled: !!token.data,
    refetchInterval: 10000, // 10 sec
    select: (data) => data.result.data,
    onError: async (error: any) => {
      if (error.response?.status === 401) {
        await token.refetch()
      }
    },
  })

  useEffect(() => {
    const fetchUsers = async () => {
      if (!personListQuery.data && !visitorListQuery.data) return
      const personList = personListQuery.data ?? []
      const visitorList = visitorListQuery.data ?? []
      const list = [...personList, ...visitorList]
      const users = await Promise.all(
        list.map(async (item) => {
          const { display_image } = await getUserImage(item.uuid!)
          return { ...item, image: display_image }
        }),
      )
      setUsers(users)
    }
    fetchUsers()
  }, [personListQuery.data, visitorListQuery.data])

  return {
    users,
    query: {
      personList: personListQuery,
      visitorList: visitorListQuery,
    },
  }
}
