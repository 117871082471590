export const tabs = [
  {
    key: '/gipsense',
    label: '3D數位雙生',
  },
  {
    key: '/dashboard',
    label: '儀表板',
  },
  {
    key: '/abnormal-record',
    label: '異常記錄',
  },
]

export const carouselResponse = [
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 3,
    },
  },
  {
    breakpoint: 768,
    settings: {
      slidesToShow: 2,
    },
  },
  {
    breakpoint: 480,
    settings: {
      slidesToShow: 1,
    },
  },
]

export const BIMStatusPriority: Record<string, number> = {
  行動: 0,
  警戒: 1,
  正常: 2,
}

export const SENSOR_NAME_MAP: Record<string, string> = {
  '4975BC2F004B1200': '精密儀器室',
  'A3EAAE2F004B1200': '前門',
  '2265CD2F004B1200': '中央實驗桌',
  '8120A82D004B1200': '粉塵區',
  '027ABC2F004B1200': '後門',
}
