import { useSettingStore } from '@/hooks'
export const GIPSLogo = ({ size = 160 }) => {
  const { theme } = useSettingStore()
  const color = theme === 'dark' ? '#fff' : '#3d3d3d'
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 103 34"
      width={size}
      height={(size * 32) / 103}
    >
      <g>
        <path
          fill="#00e580"
          strokeWidth={0}
          d="M16,28.16c2.6240039,0,5.052793-.8320117,7.04-2.2496094v4.4608008c-2.1248047,1.0432031-4.5151953,1.6288086-7.04,1.6288086C7.1648047,32,0,24.8351953,0,16S7.1648047,0,16,0c3.9648047,0,7.5967969,1.4463867,10.3935937,3.84h-10.3935937c-6.7167969,0-12.16,5.4432031-12.16,12.16s5.4432031,12.16,12.16,12.16Z"
        />
        <path
          fill="#00e580"
          strokeWidth={0}
          d="M27.52,27.0975977v-6.7775977c0-.1280078-.0032031-.2559961-.012793-.3840039-.0383984-.5856055-.1696094-1.1456055-.3775977-1.667207-.8160156-2.0767969-2.8416016-3.5487891-5.2096094-3.5487891h-6.56v4.48h6.56c.3456055,0,.652793.1567969.8575977.4032031-.2911914.5503906-.6495898,1.0591992-1.0591992,1.5167969-1.4080078,1.5711914-3.4495898,2.56-5.7183984,2.56-4.2335937,0-7.68-3.4464063-7.68-7.68,0-4.2336133,3.4464063-7.68,7.68-7.68h14.0351953c1.2544141,2.2816016,1.9648047,4.8991992,1.9648047,7.68,0,4.3071875-1.7056055,8.2208008-4.48,11.0975977Z"
        />
      </g>
      <g>
        <rect
          fill={color}
          strokeWidth={0}
          x="60.7177159"
          y="6.0800015"
          width="4.5348571"
          height="19.839997"
        />
        <path
          fill={color}
          strokeWidth={0}
          d="M102.4,19.7171629c0,3.7681867-2.790346,6.2028371-7.1097946,6.2028371-1.2017344,0-2.5196842-.1913108-3.7469347-.5257545-.9225614-.2522556-1.7941077-.5838708-2.5267595-.9764135l-.2012405-.1077042c-.007084-.0028371-.0155865-.007084-.0226705-.0113396-.0949461-.048178-.1884824-.0977832-.2806002-.1473797l2.1894788-3.7922757c.3953884.1984035.7964509.3698722,1.1932578.5172606.6759626.2522469,1.3391931.4322268,1.9428973.5427595.5129877.0963647.9820703.1431328,1.3760402.1431328,1.0302656,0,2.2589174-.257921,2.2589174-1.488,0-.5824436-.2182455-.9834975-1.3689475-1.4426504-.1573181-.0637732-.3415363-.1275463-.5456138-.1927294-.279173-.0906992-.5937746-.1842268-.9183058-.2806002-.6901306-.2054788-1.4851629-.4421479-2.2419124-.7369074-.4690653-.1842355-.9239799-.3897143-1.3278622-.6249648-1.7204135-1.001921-2.5579498-2.5196755-2.5579498-4.6425614,0-3.6902369,2.7010826-6.0738722,6.8802355-6.0738722.4662455,0,.9452232.0283446,1.425654.0821967.5966116.1261278,1.2003158.296178,1.7898348.5016655,1.0245915.3557042,2.0052606.8162757,2.8229548,1.3377832.0382656.024089.0750954.0481867.1119425.0722757l-2.2008097,3.8121177c-1.009005-.6334674-2.0690251-1.0841177-2.9731802-1.3420388-.1006116-.0297545-.1983862-.0552706-.2933237-.0793597-.2111702-.0198421-.4053181-.0283446-.5810424-.0283446-1.6977344,0-2.0534386.726995-2.0534386,1.3363647,0,.5696942.216827.9452405,1.4043761,1.4100589.0042556.0014185.0070926.0028371.0113482.0042556.3741278.1459612.8913884.296178,1.439822.4563158.5342483.1558909,1.1294587.3287782,1.721832.5356842.6703058.2324135,1.3377746.5073309,1.9046317.8474487,1.6438996.9834975,2.4771629,2.5621967,2.4771629,4.6907394Z"
        />
        <path
          fill={color}
          strokeWidth={0}
          d="M80.2053241,6.08h-9.5657197v19.8399914l4.5348597.0000086v-6.8022857h5.03086c3.1697864,0,5.7394232-2.9185843,5.7394232-6.5188485v-.0000086c.0000086-3.6002642-2.5696368-6.5188571-5.7394232-6.5188571ZM80.2053241,14.5828571h-5.0308513v-3.968h5.0308513c.2853489,0,.5326917.2325432.654737.371161.3494249.3968675.5498265.9847344.5498265,1.6128477,0,1.2111105-.7134242,1.9839914-1.2045635,1.9839914Z"
        />
        <path
          fill={color}
          strokeWidth={0}
          d="M54.9938743,9.42304c-1.01184-1.0756114-2.0591086-1.8862171-3.1248-2.4148114l-.0170057-.0085029c-1.2881829-.6107886-2.6897371-.9197257-4.1664-.9197257-2.74784,0-5.1144686.9792457-7.0318629,2.90656-1.9202286,1.9230629-2.8938057,4.29536-2.8938057,7.0517029,0,1.08128.1544686,2.1058743.4591543,3.0695314v.0014171c.0552686.1771429.1162057.3500343.1828114.5229257.4733257,1.2371657,1.2088229,2.3694629,2.20224,3.3855543,1.8904686,1.9258971,4.19616,2.9023086,6.8533029,2.9023086,2.7365029,0,5.0266057-1.0203429,6.8093714-3.0369371.7893486-.9041371,1.3618743-1.89472,1.6977371-2.9448229l.0056686-.0155886c.3287771-1.0826971.496-2.3297829.496-3.7086629v-1.5588571h-9.3531429v4.5348571h3.9637486c-.2111543.3840457-.5087543.7397486-.8928,1.0699429-.7921829.67456-1.65664,1.0033371-2.6401371,1.0033371-1.1918171,0-2.2830171-.4619886-3.33312-1.4072229-.8261943-.7496686-1.3349486-1.6368-1.5390171-2.68832-.0368457-.18848-.0651886-.3826286-.0821943-.5824457-.0155886-.1771429-.0226743-.3599543-.0226743-.5470171,0-1.52768.4832457-2.7563429,1.4865829-3.7653486.9948343-1.0175086,2.1965714-1.5135086,3.6760686-1.5135086,1.1266286,0,2.1242971.36704,3.0199314,1.1138743v.0014171l4.2443429-2.4516571Z"
        />
      </g>
    </svg>
  )
}
