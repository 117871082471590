export const Colors = {
  light: {
    primary: '#413fd7',
    background: '#eff0f6',
    tintBackground: '#fff',
    text: '#000',
    selectedText: '#fff',
    title: '#858a96',
    card: '#ffffff',
    danger: '#e4463b',
    warning: '#f3b34d',
    success: '#5fcf75',
    border: '#f0f0f0',
    divider: '#e8e8e8',
  },
  dark: {
    primary: '#413fd7',
    background: '#171c1f',
    tintBackground: '#262b2e',
    text: '#fff',
    selectedText: '#fff',
    title: '#858a96',
    card: '#262a33',
    danger: '#e4463b',
    warning: '#f3b34d',
    success: '#5fcf75',
    border: '#303030',
    divider: '#4c5261',
  },
}
