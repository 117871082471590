import { Col, Flex } from 'antd'

import { SensorCard } from '../molecules'

export const SensorList = ({ data }: { data: GetSensorsResponse }) => {
  const sensors =
    Object.keys(data).length > 0
      ? Object.entries(data).map(([name, values]) => ({
          title: name,
          data: values,
        }))
      : Array.from({ length: 5 }, (_, i) => ({ title: `Sensor ${i + 1}` }))

  return (
    <Col>
      <Flex vertical gap={12}>
        {sensors.map((sensor, index) => (
          <Col flex={1} key={sensor.title || index}>
            <SensorCard {...sensor} />
          </Col>
        ))}
      </Flex>
    </Col>
  )
}
