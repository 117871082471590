import { GetEventResponse } from '@brilltek42/lab-iot-types'
import { Flex } from 'antd'

import { AbnormalAlertCard } from '../molecules'

export const AIScan = ({ data }: { data: GetEventResponse[] }) => {
  const getEventData = (cameraId: string) => {
    return data?.find((event) => event.cameraId === cameraId)
  }

  return (
    <Flex gap={24} className="h-full">
      {['1', '2', '3'].map((cameraId) => (
        <AbnormalAlertCard
          key={cameraId}
          cameraId={cameraId}
          data={getEventData(cameraId)}
        />
      ))}
    </Flex>
  )
}
