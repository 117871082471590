import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'

import en from './en/main.json'
import zh_tw from './zh_TW/main.json'

export const resources = {
  en: {
    translation: en,
  },
  zh: {
    translation: zh_tw,
  },
}

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: 'zh',
  lng: 'zh',
})

export default i18n
