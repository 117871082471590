import { GetEventResponse } from '@brilltek42/lab-iot-types'
import { io, Socket } from 'socket.io-client'

const SOCKET_URL = import.meta.env.VITE_API_URL

export const socket: Socket<{ event: GetEventResponse }, never> = io(
  SOCKET_URL,
  {
    autoConnect: true,
    reconnection: true,
    reconnectionAttempts: 5,
    reconnectionDelay: 1000,
  },
)

socket.on('connect', () => {
  console.log('Socket connected')
})

socket.on('disconnect', () => {
  console.log('Socket disconnected')
})

socket.on('connect_error', (error) => {
  console.error('Socket connection error:', error)
})

export default socket
