import { Col, Flex, notification, Row } from 'antd'

import { useEvents, useSensors, useUsers, useUWB } from '@/hooks'

import { AIScan, PersonBlock, SensorList } from '../organisms'

export const DashboardPage = () => {
  const { events } = useEvents()
  const { personBlockTabs } = useUWB()
  const { query: sensorsQuery } = useSensors()
  const { users } = useUsers()

  const [api, contextHolder] = notification.useNotification({ stack: false })

  return (
    <Flex vertical className="h-full overflow-auto">
      {contextHolder}
      <div className="flex h-full flex-row gap-6 p-6">
        <Col span={24} className="flex flex-col gap-5">
          <Row>
            <Col span={24}>
              <AIScan data={events || []} />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={17}>
              <PersonBlock tabs={personBlockTabs} users={users} />
            </Col>
            <Col xs={24} md={7}>
              <SensorList data={sensorsQuery.data || {}} />
            </Col>
          </Row>
        </Col>
      </div>
    </Flex>
  )
}
