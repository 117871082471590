import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { TabsProps } from 'antd'
import { Tabs } from 'antd'

import { useColor } from '@/hooks'

import { PersonList } from '../molecules'
import { PersonCarousel } from './PersonCarousel'

interface PersonBlockProps {
  tabs: PersonBlockTab[]
  users: (UserData & { image: string })[]
}

const PersonNumbers = ({ value }: { value: number }) => {
  const colors = useColor()
  const { t } = useTranslation()
  return (
    <div
      className="flex w-full flex-row justify-end gap-1 px-16 text-lg font-bold 2xl:text-3xl"
      style={{ color: colors.title }}
    >
      {t('Dashboard.InCount')}：
      <span style={{ color: colors.warning }}>{value}</span>
    </div>
  )
}

export const PersonBlock = ({ tabs = [], users = [] }: PersonBlockProps) => {
  const { t } = useTranslation()
  const [activeKey, setActiveKey] = useState('entry')

  const onChange = (key: string) => {
    setActiveKey(key)
  }

  const items: TabsProps['items'] = [
    {
      key: 'entry',
      label: (
        <span className="text-base 2xl:text-2xl">{t('Dashboard.InCount')}</span>
      ),
      children: <PersonCarousel data={users} />,
    },
    ...tabs.map((tab) => ({
      key: tab.key,
      label: (
        <span className="text-base 2xl:text-2xl">
          {tab.label + `(${tab.data.length})`}
        </span>
      ),
      children: <PersonList key={tab.key} data={tab.data} />,
    })),
  ]

  const inCount = tabs?.length
    ? tabs.reduce((acc, tab) => acc + tab.data.length, 0)
    : 0

  return (
    <Tabs
      defaultActiveKey="entry"
      activeKey={activeKey}
      tabBarExtraContent={
        activeKey === 'entry' ? <PersonNumbers value={inCount} /> : null
      }
      items={items}
      onChange={onChange}
    />
  )
}
