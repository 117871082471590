import { PropsWithChildren } from 'react'
import { Card, CardProps } from 'antd'

import { useColor } from '@/hooks'

export const StyledCard = ({
  children,
  ...restProps
}: PropsWithChildren<CardProps>) => {
  const colors = useColor()
  return (
    <Card
      {...restProps}
      style={{ backgroundColor: colors.card, ...restProps.style }}
    >
      {children}
    </Card>
  )
}
