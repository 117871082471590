import { MutationFunction, useQuery } from '@tanstack/react-query'
import axios from 'axios'

import { API } from '@/utils/API'

export const useToken = () => {
  const postGenerateToken: MutationFunction<
    PostGenerateTokenResponse
  > = async () => {
    const { data: response } = await axios.post<PostGenerateTokenResponse>(
      API.airafacelite.generateToken,
      {
        username: import.meta.env.VITE_FACE_TABLET_USERNAME,
        password: import.meta.env.VITE_FACE_TABLET_PASSWORD,
      },
    )
    return response
  }

  const token = useQuery({
    queryKey: ['generateToken'],
    queryFn: postGenerateToken,
    select: (data) => data.token,
    refetchInterval: 1000 * 60 * 1,
  })

  return {
    token,
  }
}
