import { Image } from 'antd'

import ScanGif from '@/assets/img/Scan.gif'

export const AIScanningImage = () => {
  return (
    <Image
      src={ScanGif}
      alt="scan"
      width="100%"
      height={window.innerHeight * 0.29}
      className="rounded-lg object-cover"
      preview={false}
    />
  )
}
