import { Table, TablePaginationConfig, TableProps } from 'antd'

import { useColor } from '@/hooks'

interface StyledTableProps extends TableProps<any> {
  columns: any[]
  data: any[]
  pagination: TablePaginationConfig
  loading: boolean
}

export const StyledTable = ({
  columns,
  data,
  pagination,
  loading,
}: StyledTableProps) => {
  const colors = useColor()
  return (
    <Table
      columns={columns}
      dataSource={data}
      components={{
        header: {
          cell: ({ children, ...props }: any) => (
            <th
              {...props}
              className="text-base 2xl:text-2xl"
              style={{
                backgroundColor: colors.background,
                borderBottomWidth: 0,
              }}
            >
              {children}
            </th>
          ),
        },
        body: {
          cell: ({ children, ...props }: any) => (
            <td
              {...props}
              className="text-base 2xl:text-2xl"
              style={{
                backgroundColor: colors.background,
                borderBottomWidth: 0,
              }}
            >
              {children}
            </td>
          ),
        },
      }}
      pagination={pagination}
      loading={loading}
    />
  )
}
