import { useEffect, useState } from 'react'
import { MutationFunction, useQuery } from '@tanstack/react-query'
import axios from 'axios'

import { API } from '@/utils/API'

const batchQueryBody = [
  {
    targets: {
      itemOnly: true,
    },
  },
]

const apiOptions = {
  headers: {
    'x-app-id': import.meta.env.VITE_UWB_APP_ID,
    'x-api-key': import.meta.env.VITE_UWB_API_KEY,
  },
}

export const useUWB = () => {
  const [personBlockTabs, setPersonBlockTabs] = useState<PersonBlockTab[] | []>(
    [],
  )

  const postTargetStateBatchQuery: MutationFunction<
    GetTargetStateBatchQueryResponse
  > = async () => {
    const res = await axios.post<GetTargetStateBatchQueryResponse>(
      API.UWB.batchQuery,
      batchQueryBody,
      apiOptions,
    )
    return res.data
  }

  const targetStateBatchQuery = useQuery({
    queryKey: ['targetStateBatchQuery'],
    queryFn: postTargetStateBatchQuery,
    refetchInterval: 10000, // 10 sec
  })

  const postItemQueryByUids = async (data: string[]) => {
    const res = await axios.post<GetItemQueryByUidsResponse>(
      API.UWB.itemQueryByUids,
      data,
      apiOptions,
    )
    return res.data
  }

  const getItemKinds = async () => {
    const res = await axios.get<GetItemKindsResponse>(
      API.UWB.itemKinds,
      apiOptions,
    )
    return res.data
  }

  const itemKindQuery = useQuery({
    queryKey: ['itemKindQuery'],
    queryFn: getItemKinds,
  })

  useEffect(() => {
    const fetchPersonTabs = async () => {
      const targetStateBatchQueryData = targetStateBatchQuery.data?.data ?? []
      if (!targetStateBatchQueryData?.length || !itemKindQuery.data?.data) {
        setPersonBlockTabs([])
        return
      }

      try {
        const onlineTargetStates =
          targetStateBatchQueryData[0].targetStates.filter(
            (targetState) => targetState.online,
          )
        const itemUids = new Set(
          onlineTargetStates.map((targetState) => targetState.itemUid),
        )
        const groups =
          (await postItemQueryByUids(Array.from(itemUids))).data ?? []
        const groupNames = groups.map((item) => item.kind)
        const tabs = Array.from(new Set(groupNames)).map((kind) => {
          const kindData = itemKindQuery.data?.data?.find(
            (item) => item.name === kind,
          )
          return {
            key: kind,
            label: kindData?.displayName ?? kind,
            data: groups.filter((item) => item.kind === kind),
          }
        })
        setPersonBlockTabs(tabs)
      } catch (error) {
        console.error('Error fetching:', error)
        setPersonBlockTabs([])
      }
    }

    fetchPersonTabs()
  }, [targetStateBatchQuery.data, itemKindQuery.data])

  return { personBlockTabs }
}
