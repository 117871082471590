import { useEffect, useState } from 'react'

import { useColor, useSettingStore } from '@/hooks'

export const DigitalClock = () => {
  const colors = useColor()
  const { locale } = useSettingStore()
  const [time, setTime] = useState<Date>(new Date())

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date())
    }, 1000)

    return () => clearInterval(timer)
  }, [])

  const formatTime = (date: Date) => {
    return date.toLocaleTimeString(locale, {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    })
  }

  const formatDate = (date: Date) => {
    return date
      .toLocaleDateString(locale, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(/\//g, '-')
  }

  return (
    <div className="flex flex-col gap-2 font-mono">
      <div
        className="font-light tracking-wider sm:text-3xl 2xl:text-6xl"
        style={{ color: colors.text }}
      >
        {formatTime(time)}
      </div>
      <div
        className="text-right text-lg 2xl:text-4xl"
        style={{ color: colors.title }}
      >
        {formatDate(time)}
      </div>
    </div>
  )
}
