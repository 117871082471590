import { useTranslation } from 'react-i18next'
import type { TablePaginationConfig, TableProps } from 'antd'
import { Image } from 'antd'
import dayjs from 'dayjs'

import { StyledTable } from '@/components/atoms'

interface AbnormalRecordsTableProps {
  data: DataType[]
  pagination: TablePaginationConfig
  loading: boolean
}

interface DataType {
  id: number
  createdAt: string
  cameraId: string
  type: string
  imageUrl: string
}

export const AbnormalRecordsTable = ({
  data,
  pagination,
  loading,
}: AbnormalRecordsTableProps) => {
  const { t } = useTranslation()
  const columns: TableProps<DataType>['columns'] = [
    {
      title: t('AbnormalRecords.CreatedAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => dayjs(a.createdAt).diff(dayjs(b.createdAt)),
      render: (text) =>
        text ? dayjs(text).format('YYYY-MM-DD HH:mm:ss') : '-',
    },
    {
      title: t('AbnormalRecords.CameraId'),
      dataIndex: 'cameraId',
      key: 'cameraId',
      sorter: (a, b) => a.cameraId.localeCompare(b.cameraId),
    },
    {
      title: t('AbnormalRecords.Type'),
      dataIndex: 'type',
      key: 'type',
      render: (text) => (text ? t(`EventType.${text}`, text) : '-'),
    },
    {
      title: t('AbnormalRecords.ImageUrl'),
      dataIndex: 'imageUrl',
      key: 'imageUrl',
      render: (text) =>
        text ? <Image src={text} width={80} height={80} /> : '-',
    },
  ]

  return (
    <StyledTable
      columns={columns}
      data={data}
      pagination={pagination}
      loading={loading}
    />
  )
}
