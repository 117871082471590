import { useEffect, useState } from 'react'
import { GetEventResponse, GetEventsResponse } from '@brilltek42/lab-iot-types'
import axios from 'axios'
import dayjs from 'dayjs'

import fireSound from '@/assets/sounds/fire.mp3'
import suitSound from '@/assets/sounds/suit.mp3'
import { useSettingStore } from '@/hooks'
import { socket } from '@/services/socket'
import { API } from '@/utils/API'

const apiRoute = API.event
const FireAudio = new Audio(fireSound)
const SuitAudio = new Audio(suitSound)

export const useEvents = () => {
  const [events, setEvents] = useState<GetEventResponse[]>([])
  const { warningSpeaker } = useSettingStore()
  const audioQueue: HTMLAudioElement[] = []
  const [isPlaying, setIsPlaying] = useState(false)

  useEffect(() => {
    const initializeEvents = async () => {
      const initialData = await getEventsData()
      setEvents(initialData)

      socket.on('event', ((event: GetEventResponse) => {
        handleNewEvent(event)
      }) as any)
    }

    initializeEvents()

    return () => {
      socket.off('event')
    }
  }, [warningSpeaker])

  const handleNewEvent = (event: GetEventResponse) => {
    setEvents((prevEvents) => {
      const updatedEvents = [event, ...prevEvents]
      return updatedEvents.sort((a, b) =>
        dayjs(b.createdAt).diff(dayjs(a.createdAt)),
      )
    })

    if (warningSpeaker) {
      playAudioForEvent(event)
    }
  }

  const playAudioForEvent = (event: GetEventResponse) => {
    const audio = event.type === 'suit' ? SuitAudio : FireAudio

    if (audioQueue.length > 0) return

    audioQueue.push(audio)

    if (audioQueue.length === 1) {
      playNextAudio()
    }
  }

  const playNextAudio = () => {
    if (audioQueue.length === 0) {
      setIsPlaying(false)
      return
    }

    const currentAudio = audioQueue[0]
    setIsPlaying(true)
    currentAudio.play()

    currentAudio.onended = () => {
      audioQueue.shift()
      if (audioQueue.length === 0) {
        setIsPlaying(false)
      }
      playNextAudio()
    }
  }

  const getEventsData = async () => {
    try {
      const params = {
        startDateTime: dayjs().startOf('day').format(),
        endDateTime: dayjs().endOf('day').format(),
      }

      const { data } = await axios.get<GetEventsResponse>(apiRoute.list, {
        params,
      })

      return data.sort((a, b) => {
        return dayjs(b.createdAt).diff(dayjs(a.createdAt))
      })
    } catch (error) {
      console.error('Failed to get events data:', error)
      return []
    }
  }

  return { events, isPlaying }
}
