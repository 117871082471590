import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DatePicker, Flex, Select } from 'antd'
import dayjs from 'dayjs'

import { useAbnormalRecords } from '@/hooks'

import { AbnormalRecordsTable } from '../organisms'

const pageSize = 10
const { RangePicker } = DatePicker
interface DateRange {
  startDate: dayjs.Dayjs | undefined
  endDate: dayjs.Dayjs | undefined
}

export const AbnormalRecordPage = () => {
  const { i18n, t } = useTranslation()
  const [currentPage, setCurrentPage] = useState(1)
  const [eventType, setEventType] = useState('all')
  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: dayjs(),
    endDate: dayjs(),
  })
  const { abnormalRecordsQuery } = useAbnormalRecords({ dateRange: dateRange })

  const filteredData = useMemo(() => {
    if (!abnormalRecordsQuery.data) return []
    return abnormalRecordsQuery.data.filter((event) => {
      if (eventType === 'all') return true
      if (eventType === 'suit') return event.type === 'suit'
      if (eventType === 'fireworks') return event.type !== 'suit'
    })
  }, [abnormalRecordsQuery.data, eventType])

  const data = filteredData?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize,
  )

  const onPageChange = (page: number) => {
    setCurrentPage(page)
  }

  const onEventTypeChange = (value: string) => {
    setEventType(value)
    setCurrentPage(1)
  }

  const onDateRangeChange = (
    value: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null,
  ) => {
    setDateRange({
      startDate: value?.[0] ?? undefined,
      endDate: value?.[1] ?? undefined,
    })
    setCurrentPage(1)
  }

  const eventOptions = useMemo(() => {
    return [
      { label: t('All') + t('Event'), value: 'all' },
      { label: t('Suit'), value: 'suit' },
      { label: t('Fireworks'), value: 'fireworks' },
    ]
  }, [i18n.language])

  return (
    <Flex
      vertical
      gap={16}
      className="scrollbar-hide h-full overflow-y-auto px-8"
    >
      <div className="my-4 flex flex-col gap-4">
        <Flex gap={16} justify="end">
          <RangePicker
            value={[dateRange.startDate, dateRange.endDate]}
            size="large"
            onChange={(dates) => {
              if (dates === null) onDateRangeChange([null, null])
              else onDateRangeChange(dates)
            }}
            panelRender={(panel) => {
              return (
                <div className="line-clamp-1 contents text-base 2xl:text-xl">
                  {panel}
                </div>
              )
            }}
            className="h-10 w-96 [&_.ant-picker-input>input]:text-base [&_.ant-picker-input>input]:2xl:text-2xl [&_.ant-picker-suffix]:text-base [&_.ant-picker-suffix]:2xl:text-2xl"
          />
          <Select
            placeholder={t('SelectEventType')}
            size="large"
            options={eventOptions}
            optionFilterProp="label"
            className="w-60"
            value={eventType}
            labelRender={(node) => (
              <span className="line-clamp-1 text-base 2xl:text-2xl">
                {node.label}
              </span>
            )}
            optionRender={(node) => (
              <span className="line-clamp-1 text-base 2xl:text-2xl">
                {node.label}
              </span>
            )}
            onChange={onEventTypeChange}
          />
        </Flex>
      </div>
      <Flex flex={1} vertical className="h-full">
        <AbnormalRecordsTable
          data={data || []}
          pagination={{
            current: currentPage,
            pageSize,
            total: filteredData?.length,
            showSizeChanger: false,
            showQuickJumper: true,
            onChange: onPageChange,
          }}
          loading={abnormalRecordsQuery.isLoading}
        />
      </Flex>
    </Flex>
  )
}
