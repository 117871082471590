import { useEffect, useState } from 'react'
import { HiMiniSpeakerWave } from 'react-icons/hi2'
import { useLocation } from 'react-router-dom'
import { Switch } from 'antd'

import { tabs } from '@/constants'
import { useColor, useEvents, useNavigation, useSettingStore } from '@/hooks'

import './tabbar.css'

export const Tabbar = () => {
  const { navigate } = useNavigation()
  const colors = useColor()
  const { warningSpeaker, setWarningSpeaker } = useSettingStore()
  const { isPlaying } = useEvents()
  const location = useLocation()
  const [activeTab, setActiveTab] = useState(0)

  useEffect(() => {
    const currentTab = tabs.findIndex((tab) => tab.key === location.pathname)
    setActiveTab(currentTab)
  }, [location.pathname])

  const handleTabClick = (index: number) => {
    setActiveTab(index)
    navigate(tabs[index].key)
  }

  return (
    <div className="flex h-11 flex-row">
      <div
        className="tabbar mr-2"
        style={{ backgroundColor: colors.tintBackground }}
      >
        {tabs.map((tab, index) => (
          <button
            key={index}
            className="tab-item"
            style={{
              color: activeTab === index ? colors.selectedText : colors.text,
              backgroundColor: activeTab === index ? colors.primary : undefined,
            }}
            onClick={() => handleTabClick(index)}
          >
            <span className="sm:text-base 2xl:text-2xl">{tab.label}</span>
          </button>
        ))}
      </div>
      <div className="flex flex-grow items-center justify-end gap-2">
        <HiMiniSpeakerWave
          className="cursor-pointer sm:text-base 2xl:text-3xl"
          style={{ color: isPlaying ? 'red' : colors.text }}
        />
        <Switch
          checked={warningSpeaker}
          onChange={setWarningSpeaker}
          style={{ backgroundColor: warningSpeaker ? '#6F63BC' : undefined }}
          className="cursor-pointer sm:text-base 2xl:text-3xl"
        />
      </div>
    </div>
  )
}
