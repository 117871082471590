import { useTranslation } from 'react-i18next'
import { GetEventResponse } from '@brilltek42/lab-iot-types'
import { Image, Space } from 'antd'
import dayjs from 'dayjs'

import { useColor } from '@/hooks'

import { StyledCard } from '../atoms'
import { AIScanningImage } from './AIScanningImage'

interface AbnormalAlertCardProps {
  cameraId: string
  data?: GetEventResponse
}

export const AbnormalAlertCard = ({
  cameraId,
  data,
}: AbnormalAlertCardProps) => {
  const colors = useColor()
  const { t } = useTranslation()

  return (
    <StyledCard className="flex-1">
      <Space direction="vertical" className="w-full">
        <Space>
          <p
            className="text-xl font-bold 2xl:text-2xl"
            style={{ color: colors.title }}
          >
            {t('Dashboard.AbnormalAlert')}
          </p>
          <p className="text-xl font-bold text-yellow-500 2xl:text-2xl">
            CCD-{cameraId}
          </p>
        </Space>
        {!!data ? (
          <>
            <Space>
              <p className="text-xl font-bold 2xl:text-2xl">
                {dayjs(data.createdAt).format('HH:mm')}
              </p>
              <p className="text-xl font-bold 2xl:text-2xl">
                {t(`EventType.${data.type}`)}
              </p>
            </Space>
            <Image
              src={data.imageUrl || ''}
              alt="監控畫面"
              width="100%"
              height={window.innerHeight * 0.25}
              className="rounded-lg object-cover"
            />
          </>
        ) : (
          <AIScanningImage />
        )}
      </Space>
    </StyledCard>
  )
}
