import { useTranslation } from 'react-i18next'
import { Modal } from 'antd'

import FireAudio from '@/assets/sounds/fire.mp3'

import { useSettingStore } from './useSettingStore'

let modalIsOpen = false
const audio = new Audio(FireAudio)

export const useAudio = () => {
  const { t } = useTranslation()
  const { setWarningSpeaker } = useSettingStore()

  const tryPlayAudio = async () => {
    if (audio.currentTime > 0) {
      return
    }

    try {
      await audio.play()
      audio.pause()
    } catch (error: any) {
      if (error?.name === 'NotAllowedError' && !modalIsOpen) {
        modalIsOpen = true
        Modal.confirm({
          title: t('AUDIO_PERMISSION_ERROR_TITLE'),
          content: t('AUDIO_PERMISSION_ERROR'),
          cancelText: t('Cancel'),
          okText: t('Confirm'),
          onCancel: () => {
            setWarningSpeaker(false)
            modalIsOpen = false
          },
          onOk: async () => {
            await audio.play()
            audio.pause()
            setWarningSpeaker(true)
            modalIsOpen = false
          },
        })
      }
    }
  }

  return { tryPlayAudio }
}
