const API_BASE_URL = import.meta.env.VITE_API_URL
const FACE_TABLET = import.meta.env.VITE_FACE_TABLET_API
const UWB = import.meta.env.VITE_UWB_API
const AIOT = import.meta.env.VITE_AIOT_API

export const API = {
  event: {
    list: `${API_BASE_URL}/events`,
  },
  aiot: {
    sensor: `${AIOT}/api/v1/sensor/dyna/env-thd`,
  },
  airafacelite: {
    generateToken: `${FACE_TABLET}/airafacelite/generatetoken`,
    refreshToken: `${FACE_TABLET}/airafacelite/maintaintoken`,
    person: `${FACE_TABLET}/airafacelite/querypersonverifyresult`,
    visitor: `${FACE_TABLET}/airafacelite/queryvisitorverifyresult`,
    fetchPhoto: `${FACE_TABLET}/airafacelite/fetchphoto`,
  },
  UWB: {
    batchQuery: `${UWB}/rtls/rest/1.0.0/targetState/batchQuery`,
    itemQuery: `${UWB}/rtls/rest/1.0.0/item/query`,
    itemQueryByUids: `${UWB}/rtls/rest/1.0.0/item/queryByUids`,
    itemKinds: `${UWB}/rtls/rest/1.0.0/item/kinds`,
  },
}
